define("ember-cli-new-version/components/new-version-notifier", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.message}}
    {{#if (has-block)}}
      {{yield this.newVersion.latestVersion this.newVersion.currentVersion this.reload this.close}}
    {{else}}
      <div class='update-notification'>
        {{this.message}}
        {{#if this.showReload}}
          <a href='' class='update-notification__btn' {{on 'click' this.reload}}>
            {{this.reloadButtonText}}
          </a>
        {{/if}}
        <a href='' class='update-notification__close' {{on 'click' this.close}}>
          ×
        </a>
      </div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "q+UItazJ",
    "block": "[[[41,[30,0,[\"message\"]],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[30,0,[\"newVersion\",\"latestVersion\"]],[30,0,[\"newVersion\",\"currentVersion\"]],[30,0,[\"reload\"]],[30,0,[\"close\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"update-notification\"],[12],[1,\"\\n      \"],[1,[30,0,[\"message\"]]],[1,\"\\n\"],[41,[30,0,[\"showReload\"]],[[[1,\"        \"],[11,3],[24,6,\"\"],[24,0,\"update-notification__btn\"],[4,[38,3],[\"click\",[30,0,[\"reload\"]]],null],[12],[1,\"\\n          \"],[1,[30,0,[\"reloadButtonText\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[11,3],[24,6,\"\"],[24,0,\"update-notification__close\"],[4,[38,3],[\"click\",[30,0,[\"close\"]]],null],[12],[1,\"\\n        ×\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"on\"]]",
    "moduleName": "ember-cli-new-version/components/new-version-notifier.hbs",
    "isStrictMode": false
  });
  let NewVersionNotifier = _exports.default = (_class = class NewVersionNotifier extends _component2.default {
    constructor(...args) {
      super(...args);
      /** @type {import("ember-cli-new-version/services/new-version").default} */
      _initializerDefineProperty(this, "newVersion", _descriptor, this);
      _initializerDefineProperty(this, "updateMessage", _descriptor2, this);
      _initializerDefineProperty(this, "showReload", _descriptor3, this);
      _initializerDefineProperty(this, "reloadButtonText", _descriptor4, this);
    }
    /**
     * @returns {string | undefined}
     */
    get message() {
      if (this.newVersion.isNewVersionAvailable) {
        return this.updateMessage.replace('{oldVersion}', this.newVersion.currentVersion).replace('{newVersion}', this.newVersion.latestVersion);
      }
      return undefined;
    }
    close() {
      this.newVersion.ignoreVersion(this.newVersion.latestVersion);
      return false;
    }
    reload() {
      if (typeof window !== 'undefined' && window.location) {
        window.location.reload(true);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "newVersion", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updateMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.updateMessage ?? 'This application has been updated from version {oldVersion} to {newVersion}. Please save any work, then refresh browser to see changes.';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showReload", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.showReload ?? true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "reloadButtonText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.reloadButtonText ?? 'Reload';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reload", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reload"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NewVersionNotifier);
});