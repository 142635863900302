define("ember-svg-jar/inlined/linktree-button-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M54.036 23.414l16.02-16.468 9.298 9.522-16.804 16.02h23.638v13.22h-23.75l16.916 16.43-9.298 9.336L47.09 48.395 24.125 71.474l-9.299-9.299 16.916-16.43H7.992V32.488h23.639l-16.805-16.02 9.299-9.522 16.02 16.468V0h13.89v23.414zM40.144 64.64h13.892v31.367H40.144V64.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 96 97"
    }
  };
});